<template>
  <div>
    <a-table :columns="columns" :dataSource="list" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id">
      <span
        slot="id"
        slot-scope="index"
      >
        {{index}}
      </span>
      <span slot="name" slot-scope="record"> {{ record.ru.name }}</span>
      <span slot="position" slot-scope="record"> {{ record.position }}</span>
      <span v-if="record.id !== 0" slot="active" slot-scope="record">{{ record.active ? 'Активный' : 'Не активный' }}</span>
      <span slot="action" slot-scope="record">
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$refs.editModal.open(record.id)">
          <i class="fe fe-edit mr-2" />
          Изменить
        </a>
      </span>
    </a-table>

    <edit-header-item-modal ref="editModal" @getList="$emit('getList')" />
  </div>
</template>

<script>
import EditHeaderItemModal from '@/components/content/header/EditHeaderItemModal'

export default {
  name: 'HeaderTable',

  components: {
    EditHeaderItemModal,
  },

  props: {
    list: {
      type: Array,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Позиция',
          dataIndex: 'position',
        },
        {
          title: 'Статус',
          scopedSlots: { customRender: 'active' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
}
</script>
