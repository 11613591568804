<template>
  <a-modal v-model="visible" :title="form.ru ? `${form.ru.name}` : ''" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" :disabled="isDisabled" type="primary" @click.prevent.stop="submit">
        Сохранить изменения
      </a-button>
    </template>
    <a-form-model :model="form">
      <a-form-model-item label="Название(ru)" required>
        <a-input  v-model="form.ru.name" />
      </a-form-model-item>
      <a-form-model-item label="Название(ua)" required>
        <a-input  v-model="form.ua.name" />
      </a-form-model-item>
      <a-form-model-item label="Ссылка" required>
        <a-input  v-model="form.alias" />
      </a-form-model-item>
      <a-form-model-item label="Позиция" required>
        <a-input  v-model="form.position" />
      </a-form-model-item>
      <a-form-model-item label="Статус" required>
        <a-select v-model="form.active" placeholder="please select your zone">
          <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import statuses from '@/entities/statuses'

export default {
  name: 'EditHeaderItemModal',

  data() {
    return {
      form: {
        ua: {
          name: '',
        },
        ru: {
          name: '',
        },
      },
      statuses,
      visible: false,
    }
  },

  computed: {
    isDisabled() {
      const { form } = this
      return !(form.ru?.name && form.ua?.name && `${form.active}` && form.alias && `${form.position}`)
    },
  },

  methods: {
    async open(id) {
      try {
        const form = (await this.$services.get(`admin/menus/${id}`)).data.data.category
        this.form = {
          ...this.form,
          ...form,
        }
        this.visible = true
      } catch (e) {
        console.error(e)
      }
      this.visible = true
    },

    async submit() {
      try {
        await this.$services.put(`admin/menus/${this.form.id}`, this.form)
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.form = {
        ua: {
          name: '',
        },
        ru: {
          name: '',
        },
      }
      this.visible = false
      this.$emit('getList')
    },
  },
}
</script>
