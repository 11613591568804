<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Настройка: Шапка</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Настройка шапки</h5>
        </div>
      </div>
      <div class="card-body">
        <header-table :list="list" @getList="getList" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTable from '@/components/content/header/HeaderTable'

export default {
  name: 'SiteHeader',

  components: {
    HeaderTable,
  },

  data() {
    return {
      list: [],
    }
  },

  created() {
    this.getList()
  },

  methods: {
    async getList() {
      try {
        this.list = (await this.$services.get('admin/menus')).data.data.categories
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>
